import { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";

import { Product as ProductType } from "../../data/types";

import api from "../../api/api";
import { BASE_URL} from "../../api/constants";

import './Product.css';

const Product = () => {

  const { product_id } = useParams();

  const [product, setProduct] = useState<ProductType | null>(null);

  const getProduct = async (product_id: number) => {

    const result = await api.getProductById(product_id);

    if (result.data) {
      setProduct(result.data[0]);
    }

  }

  useEffect(() => {

    void getProduct(Number(product_id));

  }, [product_id]);

  return (
    <>
      { product &&
        <div className="container product">
          <p className = 'product-page-title'>{ product.name }</p>
          <div className = 'product-page'>
            <div className="product-page-left">
              <img src={ `${BASE_URL}/${product.super_big_image}` } alt={ product.name } className='product-page-image'></img>
            </div>
            { product.content &&
              <div className='product-page-right'>
                <div className='product-page-right-info'>
                  <div dangerouslySetInnerHTML={{__html: product.content}}>

                  </div>
                  <p className='product-page-price'>{ `Цена: ${product.price}` }</p>
                </div>
              </div>}
          </div>
        </div>
      }
    </>



  );
}

export default Product;