// import { useEffect, useState, createContext } from "react";
import { Outlet } from "react-router-dom";

// import { User, ResponseWithUser } from "../../api/schemas";

// import Auth from "../../components/Auth/Auth";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

// import Functions from "../../api/functions";

import "./Layout.css";

// export const commonContext = createContext({});

const Layout = () => {

  // const [user, setUser] = useState<User | undefined>(undefined);
  // const [authFormIsOpen, setAuthFormIsOpen] = useState(false);
  //
  // const checkAuth = async (): Promise<void> => {
  //   const res: ResponseWithUser = await Functions.checkAuth();
  //   if (res.result) {
  //     setUser(res.data);
  //   } else {
  //     setUser(undefined);
  //     console.error(`Ошибка: ${res.error}`);
  //   }
  // };
  //
  // const logout = async (): Promise<void> => {
  //   const res = await Functions.logout();
  //   if (res.result) {
  //     setUser(undefined);
  //     window.location.replace('/');
  //   } else {
  //     console.error(`Ошибка: ${res.error}`);
  //   }
  // };
  //
  // const openAuthForm = () => {
  //   setAuthFormIsOpen(true);
  // }
  //
  // const closeAuthForm = () => {
  //   setAuthFormIsOpen(false);
  // }
  //
  // useEffect(() => {
  //   void checkAuth();
  // }, []);

	return (
    <>
      <div className="container">
        <Header />
      </div>
      <main className="main">
        <Outlet />
      </main>
      <div className="container">
        <Footer />
      </div>
    </>
	);
};

export default Layout;
