import { FC, createContext, useEffect, useState, Dispatch, SetStateAction } from "react";
import { Heading } from "@chakra-ui/react";

import { ProductList } from "../../components/ProductList/ProductList";
import api from "../../api/api";
import SearchString from "../../components/ParseXml/SearchString";
import {ProductList as ProductListType} from "../../data/types";

// import { products } from "../../data/products";

import "./Catalog.css";

export type CatalogContextType = {
  searchValue: string[];
  setSearchValue: Dispatch<SetStateAction<string[]>>
};

export const CatalogContext = createContext<CatalogContextType>({ searchValue: [], setSearchValue: () => {} });

const Catalog: FC = () => {

  const [page, setPage] = useState(1);
  const [next, setNext] = useState(false);
  const [products, setProducts] = useState<ProductListType>([]);
  const [searchValue, setSearchValue] = useState<string[]>(() => {
    const result = window.localStorage.getItem('searchValue');
    if (result) {
      return  JSON.parse(result);
    } else {
      window.localStorage.setItem('searchValue', JSON.stringify([]));
      return [];
    }
  });//'1105693', '1105695'

  const getProducts = async () => {

    const result = await api.getProducts(page, searchValue.map(Number));

    if (result.data) {

      setProducts([
        ...products,
        ...result.data
      ]);

      if (result.hasMore) {
        setNext(true);
      } else {
        setNext(false);
      }

    }

  }

  useEffect(() => {

    window.localStorage.setItem('searchValue', JSON.stringify(searchValue));
    setProducts([]);
    setPage(0);

  }, [searchValue]);

  useEffect(() => {

    if (page === 0) {
      setPage((page) => page + 1);
    }

    void getProducts();

  }, [page]);

  return (
    <CatalogContext.Provider value={{ searchValue, setSearchValue }}>
      <div className="container catalog">
        <Heading as='h1'  color='var(--red)' fontSize='18px' lineHeight='21px'>
          КАТАЛОГ СУВЕНИРНОЙ ПРОДУКЦИИ
        </Heading>
        <div className='data'>
          <SearchString />
          <ProductList products={ products } />
          {
            next  &&
              <button
                className="btn"
                onClick={() => {
                  setPage((page) => page + 1);
                }}
                type="button"
              >
                Показать ещё
              </button>
          }
        </div>

      </div>
    </CatalogContext.Provider>
  );
};

export default Catalog;
