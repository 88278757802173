import { FC } from "react";
import {Heading} from "@chakra-ui/react";

import WhatsappImg from "../../images/watsapp.svg";
import TelegramImg from "../../images/telegram.svg";
import VkImg from "../../images/vk.svg";

import "./Contacts.css";


const Contacts: FC = () => {

  return (
    <div className="container contacts">
      <Heading as='h1'  color='var(--red)' fontSize='18px' lineHeight='21px'>
        НАШИ КОНТАКТЫ
      </Heading>
      <ul className="grid">
        <li className="grid-item grid-left">
          <p>Адрес: г. Краснодар, ул. Рашпилевская, 321</p>
          <iframe className='map-container'
            src="https://yandex.ru/map-widget/v1/?um=constructor%3A70e626581fa80c3a3972860de1b16fbb5bbb3d38aafc0fed9ca688f3cc8a28b1&amp;source=constructor"
          >
          </iframe>
          {/*<MapElement />*/}
          <p>Время работы: 09:00 — 18:00 (кроме субботы, воскресенья.)</p>
        </li>
        <li className="grid-item grid-right">
          <ul className="contacts-list">
            <li className="contacts-list-item">
              <p>Телефоны:</p>
              <div className="contacts-list-item-info">
                <ul className='contacts-phones-list'>
                  <li className='contacts-phones-list-item'><a href="tel:+79280364901" className="link">+7 (928) 036-49-01</a></li>
                  <li className='contacts-phones-list-item'><a href="tel:+79184161950" className="link">+7 (918) 416-19-50</a></li>
                  <li className='contacts-phones-list-item'><a href="tel:+79184970486" className="link">+7 (918) 497-04-86</a></li>
                </ul>
              </div>
            </li>
            <li className="contacts-list-item">
              <p>Почта:</p>
              <div className="contacts-list-item-info">
                <a href="mailto:absolut_media@mail.ru" className="link">absolut_media@mail.ru</a>
              </div>
            </li>
            <li className="contacts-list-item">
              <p>Сайт:</p>
              <div className="contacts-list-item-info">
                <a href="https://Абсолютмедиа.рф" className="link" target='_blank'>Абсолютмедиа.рф</a>
              </div>
            </li>
            <li className="contacts-list-item">
              <p>Соц. сети и мессенджеры:</p>
              <div className="contacts-list-item-info">
                <div className='contacts-list-social'>
                  <a href="whatsapp://send?phone=79280364901" target="_blank" className="link">
                    <img src={WhatsappImg} alt='WhatsApp' className="social-image"/>
                  </a>
                  <a href="https://t.me/+79280364901" target="_blank" className="link">
                    <img src={TelegramImg} alt='Телеграм' className="social-image"/>
                  </a>
                  <a href="https://vk.ru/id888726255" target="_blank" className="link">
                    <img src={VkImg} alt='Вконтакте' className="social-image"/>
                  </a>
                </div>
              </div>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default Contacts;
