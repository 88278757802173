import { FC } from 'react';
import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Stack,
  Heading,
  Text,
  Box,
  Card,
  CardBody,
  Image,
  SimpleGrid
} from "@chakra-ui/react";

import { Service } from "../../data/types";

import "./ServiceItem.css";

interface ServiceItemProps {
  service: Service;
}

const ServiceItem: FC<ServiceItemProps> = ({ service }) => {
  return (
    <AccordionItem
      className="service-item"
      border='1px solid var(--chakra-colors-chakra-border-color)'
      // borderRadius={5}
    >
      <AccordionButton
        className='service-item-info'
        // display='flex'
        width='100%'
      >
        <Image
          className='service-item-info-image'
          objectFit='cover'
          maxW={{ base: '100%', sm: '200px' }}
          src={service.imageUrl[0]}
          alt='Дизайн'
          // borderRadius={5}
        />
        <Stack
          flexGrow={1}
        >
          {/*<Heading size='md'></Heading>*/}
          <Text
            className='service-item-info-text'
          >
            <strong className="red">{`${service.title} `}</strong>
            {service.text}
          </Text>
        </Stack>

        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel pb={4}>
        <SimpleGrid spacing={2} templateColumns='repeat(auto-fill, minmax(300px, 1fr))'>
          { service.imageUrl.map((item, index) => (
            // <Card maxW='sm' key={ index }>
            //   <CardBody>
            //
            //   </CardBody>
            // </Card>
            <Image
              key={ index }
              src={ item }
              alt='Дизайн'
              // borderRadius='3px'
            />
          ))
          }
        </SimpleGrid>
      </AccordionPanel>
    </AccordionItem>
  );
}

export default ServiceItem;