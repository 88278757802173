import axios, { AxiosError } from 'axios';

import { Product, ProductList } from "../data/types";
import { API_PRODUCTS } from "./constants";

interface resType {
  result: boolean,
  message?: string,
  hasMore?: boolean,
  data?: ProductList
}

function isAxiosError(candidate: unknown): candidate is AxiosError {
  if (candidate && typeof candidate === 'object' && 'isAxiosError' in candidate) {
    return true;
  }
  return false;
}

// Возвращаем продукты постранично
async function getProducts(page: number, search: number[]):Promise<resType> {

  try {

    const apiResponse =  await axios.get(
      API_PRODUCTS,
      {
        params: { page, search },
        headers: {
          'Accept': 'application/json',
          // "Access-Control-Allow-Origin": "*"
          // "Access-Control-Allow-Origin": "https://absolutmedia.myownsite.ru"

        }
      }
    );

    // console.log(apiResponse.data);

    return apiResponse.data;

  } catch (error: unknown) {

    let message = 'Error!';

    if (isAxiosError(error)) {
      message = error.message.toString();
    }

    console.log(message);

    return {
      result: false,
      message: message,
    };

  }

}

async function getProductById(product_id: number):Promise<resType> {

  try {

    const apiResponse =  await axios.get(
      API_PRODUCTS + '/' + product_id,
      {
        headers: {
          'Accept': 'application/json',
          // "Access-Control-Allow-Origin": "*"
          // "Access-Control-Allow-Origin": "https://absolutmedia.myownsite.ru"
        }
      }
    );

    return apiResponse.data;

  } catch (error: unknown) {

    let message = 'Error!';

    if (isAxiosError(error)) {
      message = error.message.toString();
    }

    return {
      result: false,
      message: message,
    };

  }

};

const api =  {
  getProducts,
  getProductById
}

export default api;
