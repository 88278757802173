import Logo from "../../images/_logo.jpg";

import "./Footer.css";

const Footer = () => {
  return (
    <footer className="footer outline">
      <div className="copyrights">
        <span >2024 © Все права защищены</span>
      </div>
      {/*<img src={Logo} alt='Логотип Абсолют Медиа' className='footer_logo'></img>*/}
    </footer>
  );
}

export default Footer;