import { FC } from 'react';

import { Product } from "../../data/types";
import { BASE_URL } from "../../api/constants";

import './ProductCard.css';

interface ProductCardProps {
  item: Product;
}

export const ProductCard: FC<ProductCardProps> = ({item}) => {
  return (
    <li className = 'product-card'>
      <a href={`${BASE_URL}/catalog/${item.product_id}`}>
      {/*<a href='#'>*/}
        <img src={ item.small_image } alt={ item.name } className='product-card__image'></img>
        <div className='product-card__price-container'>
          { <p className='product-card__price'>{ item.price }</p> }
          {/*{ item.weight && (<p className='product-card__discount'>{ item.group } Р</p>)}*/}
        </div>
        <p className = 'product-card__title'>{ item.name }</p>
      </a>
    </li>
  );
}
