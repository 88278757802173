import { FC } from "react";
// import { Carousel } from 'antd';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';

import sl_1_1 from "../../images/slider/1_1.jpg";
import sl_1_2 from "../../images/slider/1_2.jpg";
import sl_1_3 from "../../images/slider/1_3.jpg";
import sl_2_1 from "../../images/slider/2_1.jpg";
import sl_2_2 from "../../images/slider/2_2.jpg";
import sl_2_3 from "../../images/slider/2_3.jpg";

import 'pure-react-carousel/dist/react-carousel.es.css';
import './Slider.css';

const MySlider: FC = () => {
  return (
    <CarouselProvider
      naturalSlideWidth={1}
      naturalSlideHeight={1}
      totalSlides={2}
      isPlaying={true}
      interval={7000}
    >
      <Slider className="slider-container">
        <Slide index={0}>
          <div className="slide">
            <div><img src={ sl_1_1 } alt="Дизайн" className='slider-image' /></div>
            <div><img src={ sl_1_2 } alt="Дизайн" className='slider-image' /></div>
            <div><img src={ sl_1_3 } alt="Дизайн" className='slider-image' /></div>
          </div>
        </Slide>
        <Slide index={1}>
          <div className="slide">
            <div><img src={ sl_2_1 } alt="Полиграфия" className='slider-image' /></div>
            <div><img src={ sl_2_2 } alt="Полиграфия" className='slider-image' /></div>
            <div><img src={ sl_2_3 } alt="Полиграфия" className='slider-image' /></div>
          </div>
        </Slide>
      </Slider>
      {/*<ButtonBack>Back</ButtonBack>*/}
      {/*<ButtonNext>Next</ButtonNext>*/}
    </CarouselProvider>
  );
}

export default MySlider;