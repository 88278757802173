import { FC } from "react";

import { ProductList as  ProductListType } from "../../data/types";

import { ProductCard } from "../ProductCard/ProductCard";

import './ProductList.css';

interface ProductListProps {
  products: ProductListType;
}

export const ProductList: FC<ProductListProps> = ({ products }) => {
  return <ul className='product-list'>
    { products.map((item) => (
      <ProductCard  key = { item.product_id }
        item = { item }
      />
    ))
    }
  </ul>
};
