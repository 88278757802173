import { FC } from "react";
import {Heading} from "@chakra-ui/react";


import ServiceList from "../../components/ServiceList/ServiceList";

import { services } from "../../data/services";

import "./Services.css";


const Services: FC = () => {

  return (
    <div className="container services">
      <Heading as='h1'  color='var(--red)' fontSize='18px' lineHeight='21px'>
        НАШИ УСЛУГИ
      </Heading>
      <ServiceList services={services} />
    </div>
  );
};

export default Services;
