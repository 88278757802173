import "./404.css";


const Page404 = () => {

  return (
    <>
      <div className="container">
        <h2 className="header404">
          404 Not Found
        </h2>
      </div>
    </>
  );
};

export default Page404;

// import React, { useEffect, useState } from 'react';
// import xml2js from 'xml2js';
//
// const Page404 = () => {
//   const [data, setData] = useState({});
//   useEffect(() => {
//     const fetchData = async () => {
//       const response = await fetch('example.xml');
//       const xmlText = await response.text();
//       xml2js.parseString(xmlText, (err, result) => {
//         if (err) {
//           console.error(err);
//         } else {
//           setData(result);
//         }
//       });
//     };
//     fetchData();
//   },[]);
//
//   if (!data) {
//     return <>
//       Loading...
//     </>
//   }
//
//   return JSON.stringify(data);
//
// }
//
// export default Page404;