import { FC } from "react";
import {Heading} from "@chakra-ui/react";

import "./Advantages.css";


const Advantages: FC = () => {

  return (
    <div className="container advantages">
      <Heading as='h1'  color='var(--red)' fontSize='18px' lineHeight='21px'>
        НАШИ ПРЕИМУЩЕСТВА
      </Heading>
      <ul className='advantages-info'>
        <li className='checked'>1. Большой опыт в сфере рекламы (16 лет).</li>
        <li className='checked'>2. Рекламное агенство полного цикла.. Изготовление дизайн-макета + изготовление рекламной продукции + доставка.</li>
        {/*<ul>*/}
        {/*  <li>Изготовление дизайн-макета</li>*/}
        {/*  <li>Изготовление рекламной продукции</li>*/}
        {/*  <li>Доставка</li>*/}
        {/*</ul>*/}
        <li className='checked'>3. Индивидуальный подход.</li>
        <li className='checked'>4. Высокое качество.</li>
        <li className='checked'>5. Низкие цены.</li>
        <li className='checked'>6. Оперативная печать от 1 часа.</li>
        <li className='checked'>7. Доставка.</li>
        <ul>
          <li>
            Бесплатная доставка по Краснодару.<br/>
            Курьер доставляет готовую продукцию до дверей вашего офиса.
          </li>
          <li>
            Доставка по всей России.<br/>
            Отправим ваш заказ любой транспортной компанией.<br/>
            Упакуем продукцию и доставим до офиса транспортной компании бесплатно.
          </li>
        </ul>
      </ul>
    </div>
  );
};

export default Advantages;
