import { FC } from "react";

import { ServiceList as  ServiceListType } from "../../data/types";

import ServiceItem from "../ServiceItem/ServiceItem";
import { Accordion } from "@chakra-ui/react";

interface ServiceListProps {
  services: ServiceListType;
}

const ServiceList: FC<ServiceListProps> = ({ services }) => {
  return <Accordion
      allowMultiple
      display="flex"
      flexDirection="column"
      gap='var(--gap)'
  >
    {
      services.map((item, index) => (
        <ServiceItem  key = { index }
                      service = { item }
        />
      ))
    }
  </Accordion>

};

export default ServiceList;