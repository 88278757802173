import React, { useEffect, useState, useContext } from 'react';
import { TreeSelect, ConfigProvider } from 'antd';

import { PageType, PageList } from "../../data/types";

import { CatalogContext } from "../../pages/Catalog/Catalog";
import xml_data from "./tree";

import "./SearchString.css";

const { SHOW_CHILD } = TreeSelect;//SHOW_PARENT, SHOW_ALL,

const SearchString = () => {

  const { searchValue, setSearchValue } = useContext(CatalogContext);
  const [treeData, setTreeData] = useState<PageList>([]);

  useEffect(() => {

    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xml_data, "text/xml");
    const catalog: PageList = [];

    const mainPage = xmlDoc.querySelector('page');

    const parsePage = (page: Element, page_id: string, page_key: string) : PageType => {

      const child_elements = page.querySelectorAll(`page[parent_page_id=\"${page_id}\"]`);

      const child_pages: PageList = [];

      let new_page_key = 0;

      child_elements.forEach((child_element) => {
        const child_id = child_element.querySelector("page_id")!.textContent || '';
        // console.log(`Ищем ${child_id} в ${page_id}`);
        child_pages.push(parsePage(child_element, child_id, page_key + '-' + new_page_key));
        new_page_key++;
      });

      const thisPage:PageType = {
        title: page.querySelector("name")!.textContent || '',
        value: page.querySelector("page_id")!.textContent || page_key,
        key: page.querySelector("page_id")!.textContent || page_key,
        children: child_pages.length > 0 ? child_pages : undefined,
        uri: page.querySelector("uri")!.textContent || '',
        page_id: page.querySelector("page_id")!.textContent || '',
      };

      return thisPage;

    }

    if (mainPage) {
      const catalog_elements = mainPage.querySelectorAll(`page[parent_page_id='1']`);
      let current_key = 0;
      catalog_elements.forEach((el) => {
        if (el.querySelector("page_id")) {
          const el_id = el.querySelector("page_id")!.textContent || '';
          catalog.push(parsePage(el, el_id, '0-' + current_key));
          current_key++;
        }
      })
      setTreeData(catalog);
    }

  }, []);

  if (treeData.length === 0) {

    return <div>Loading...</div>;

  } else {

    const onChange = (newValue: string[]) => {
      setSearchValue(newValue);
    };

    let tProps = {
      treeData,
      treeNodeFilterProp: "title",
      value: searchValue,
      onChange,
      treeCheckable: true,
      showCheckedStrategy: SHOW_CHILD,
      placeholder: 'Выберите категорию..',
      style: {
        width: '100%',
      },
    };

    return <ConfigProvider
      theme={{
        components: {
          TreeSelect: {
            colorPrimary: '#c82020',
            colorPrimaryHover: '#c82020',
          },
        },
      }}
    >
      <TreeSelect {...tProps} />
    </ConfigProvider>
  }

};

export default SearchString;
