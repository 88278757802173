import { FC } from "react";
import { Heading, Text } from '@chakra-ui/react'

import MySlider from "../../components/Slider/Slider";

import "./About.css";

const About: FC = () => {

  return (
    <>
      <div className="full-width-slider-container">
        <MySlider />
      </div>

      <div className="container about">

        <div className="short-width-slider-container">
          <MySlider />
        </div>

        <div className="about-info">

          <Heading as='h1'  color='var(--red)' fontSize='18px' lineHeight='21px'>
            РЕКЛАМНОЕ АГЕНСТВО АБСОЛЮТ&nbsp;МЕДИА
          </Heading>

          <Text color='var(--red)' fontSize='18px' lineHeight='21px'>Сувенирная продукция - это эмоции.</Text>
          <Text color='var(--red)' fontSize='18px' lineHeight='21px' mb='25px'>
            Всем приятно получать подарки, пусть и самые незначительные.
          </Text>
          <Text fontSize='18px' lineHeight='21px'>
            Рекламное агентство «Абсолют Медиа» с 2008 года работает на рынке промопродукции и бизнес-подарков.
          </Text>
          <Text fontSize='18px' lineHeight='21px' mb='25px'>
            У нас всегда широкий выбор подарков от недорогих, которые помогут «вписаться» даже в ограниченный бюджет до самых статусных персональных. Постоянный ассортимент продукции хранится на складах в Краснодаре, Москве, и Санкт-Петербурге. Компания работает, только с проверенными брендами. Мы постоянно разрабатываем и создаем принципиально новые промопродукты, поэтому всегда готовы отойти от стандартных схем и предложить заказчикам самые интересные и нестандартные варианты промопродукции и бизнес-подарков. А затем воплотить их в жизнь на самом высшем уровне. Для этого у агентства есть своя дизайн-студия и самые широкие производственные мощности. Выбор технологий производства и методов нанесения практически безграничен. Результат такой работы — уникальные решения для продвижения компаний.
          </Text>
          <Heading as='h2' color='var(--red)' fontSize='18px' lineHeight='21px' mb='25px'>
            СФЕРЫ НАШЕЙ КОМПЕТЕНЦИИ:
          </Heading>
          <ul>
            <li className='my-list'>Разработка концепций дизайна сувенирной продукции и бизнес-подарков</li>
            <li className='my-list'>Разработка концепций дизайна полиграфической продукции</li>
            <li className='my-list'>Изготовление сувенирной и полиграфической продукци</li>
            <li className='my-list'>Широкоформатная и интерьерная печать.</li>
            <li className='my-list'>Наружная реклама</li>
            <li className='my-list'>Регистрация товарного знака, логотипа,бренда</li>
          </ul>

        </div>

      </div>
    </>
  );
};

export default About;
