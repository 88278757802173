import { NavLink } from "react-router-dom";

import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
  Button,
  IconButton
} from '@chakra-ui/react'

import { ChevronDownIcon, HamburgerIcon  } from '@chakra-ui/icons';

import Logo from '../../images/logo.jpg';

import "./Header.css";

const Header = () => {

  return (
    <header className="header outline">
      <div className="header-top">
        <a href="/">
          <img src={Logo} alt='Логотип Абсолют Медиа' className='header_logo'></img>
        </a>
        <div className="header-top-right">
          <div className="header-top-right-burger">
            <Menu>
              <MenuButton
                as={IconButton}
                aria-label='Options'
                icon={<HamburgerIcon />}
                variant='outline'
              />
              <MenuList>
                <MenuItem>
                  <NavLink to={"/"} className="header_nav_item">О КОМПАНИИ</NavLink>
                </MenuItem>
                <MenuItem>
                  <NavLink to={"/services"} className="header_nav_item">УСЛУГИ</NavLink>
                </MenuItem>
                <MenuItem>
                  <NavLink to={"/catalog"} className="header_nav_item">КАТАЛОГ</NavLink>
                </MenuItem>
                <MenuItem>
                  <NavLink to={"/advantages"} className="header_nav_item">ПРЕИМУЩЕСТВА</NavLink>
                </MenuItem>
                <MenuItem>
                  <NavLink to={"/contacts"} className="header_nav_item">КОНТАКТЫ</NavLink>
                </MenuItem>
              </MenuList>
            </Menu>
          </div>
          <a href="/contacts" className='header-address link'>ул. Рашпилевская, 321</a>
          <a href="tel:+79280364901" className="header-phone link">+7 (928) 036-49-01</a>
        </div>

      </div>
      <div className='header-bottom'>
        <nav className="header_nav">
          <NavLink to={"/"} className="header_nav_item">О КОМПАНИИ</NavLink>
          <NavLink to={"/services"} className="header_nav_item">УСЛУГИ</NavLink>
          <NavLink to={"/catalog"} className="header_nav_item">КАТАЛОГ</NavLink>
          <NavLink to={"/advantages"} className="header_nav_item">ПРЕИМУЩЕСТВА</NavLink>
          <NavLink to={"/contacts"} className="header_nav_item">КОНТАКТЫ</NavLink>
        </nav>
        <div className="header-contacts">
          <a href="/contacts" className='header-address'>г. Краснодар, ул. Рашпилевская, 321</a>
          <a href="tel:+79280364901" className="header-phone">+7 (928) 036-49-01</a>
        </div>
      </div>
    </header>
  );
}

export default Header;