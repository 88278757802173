//region //Дизайн
import Design1 from "../images/services/Дизайн/1.jpg";
import Design2 from "../images/services/Дизайн/2.jpg";
import Design3 from "../images/services/Дизайн/3.jpg";
import Design4 from "../images/services/Дизайн/4.jpg";
import Design5 from "../images/services/Дизайн/5.jpg";
import Design6 from "../images/services/Дизайн/6.jpg";
import Design7 from "../images/services/Дизайн/7.jpg";
import Design8 from "../images/services/Дизайн/8.jpg";
import Design9 from "../images/services/Дизайн/9.jpg";
import Design10 from "../images/services/Дизайн/10.jpg";
import Design11 from "../images/services/Дизайн/11.jpg";
import Design12 from "../images/services/Дизайн/12.jpg";
//endregion

//region //Полиграфия
import Polygraphy1 from "../images/services/Полиграфия/1.jpg";
import Polygraphy2 from "../images/services/Полиграфия/2.jpg";
import Polygraphy3 from "../images/services/Полиграфия/3.jpg";
import Polygraphy4 from "../images/services/Полиграфия/4.jpg";
import Polygraphy5 from "../images/services/Полиграфия/5.jpg";
import Polygraphy6 from "../images/services/Полиграфия/6.jpg";
import Polygraphy7 from "../images/services/Полиграфия/7.jpg";
import Polygraphy8 from "../images/services/Полиграфия/8.jpg";
import Polygraphy9 from "../images/services/Полиграфия/9.jpg";
import Polygraphy10 from "../images/services/Полиграфия/10.jpg";
//endregion

//region //Сувенирная продукция
import Souvenirs1 from "../images/services/Сувенирная продукция/1.jpg";
import Souvenirs2 from "../images/services/Сувенирная продукция/2.jpg";
import Souvenirs3 from "../images/services/Сувенирная продукция/3.jpg";
import Souvenirs4 from "../images/services/Сувенирная продукция/4.jpg";
import Souvenirs5 from "../images/services/Сувенирная продукция/5.jpg";
import Souvenirs6 from "../images/services/Сувенирная продукция/6.jpg";
import Souvenirs7 from "../images/services/Сувенирная продукция/7.jpg";
import Souvenirs8 from "../images/services/Сувенирная продукция/8.jpg";
import Souvenirs9 from "../images/services/Сувенирная продукция/9.jpg";
//endregion

//region //Мерч и корпоративные подарки
import Gifts1 from "../images/services/Мерч и корпоративные подарки/1.jpg";
import Gifts2 from "../images/services/Мерч и корпоративные подарки/2.jpg";
import Gifts3 from "../images/services/Мерч и корпоративные подарки/3.jpg";
import Gifts4 from "../images/services/Мерч и корпоративные подарки/4.jpg";
import Gifts5 from "../images/services/Мерч и корпоративные подарки/5.jpg";
import Gifts6 from "../images/services/Мерч и корпоративные подарки/6.jpg";
import Gifts7 from "../images/services/Мерч и корпоративные подарки/7.jpg";
import Gifts8 from "../images/services/Мерч и корпоративные подарки/8.jpg";
import Gifts9 from "../images/services/Мерч и корпоративные подарки/9.jpg";
//endregion

//region //Широкоформатная и интерьерная печать
import Print1 from "../images/services/Широкоформатная и интерьерная печать/1.jpg";
import Print2 from "../images/services/Широкоформатная и интерьерная печать/2.jpg";
import Print3 from "../images/services/Широкоформатная и интерьерная печать/3.jpg";
import Print4 from "../images/services/Широкоформатная и интерьерная печать/4.jpg";
//endregion

//region //Наружная реклама
import Outdoor1 from "../images/services/Наружная реклама/1.jpg";
import Outdoor2 from "../images/services/Наружная реклама/2.jpg";
import Outdoor3 from "../images/services/Наружная реклама/3.jpg";
import Outdoor4 from "../images/services/Наружная реклама/4.jpg";
import Outdoor5 from "../images/services/Наружная реклама/5.jpg";
import Outdoor6 from "../images/services/Наружная реклама/6.jpg";
//endregion

//region //Брендирование автомобилей
import Auto1 from "../images/services/Брендирование авто/1.jpg";
import Auto2 from "../images/services/Брендирование авто/2.jpg";
import Auto3 from "../images/services/Брендирование авто/3.jpg";
import Auto4 from "../images/services/Брендирование авто/4.jpg";
import Auto5 from "../images/services/Брендирование авто/5.jpg";
//endregion

//region //Оперативная печать
import Urgent1 from "../images/services/Оперативная печать/1.jpg";
import Urgent2 from "../images/services/Оперативная печать/2.jpg";
import Urgent3 from "../images/services/Оперативная печать/3.jpg";
import Urgent4 from "../images/services/Оперативная печать/4.jpg";
//endregion

//region //POS-материалы
import POS1 from "../images/services/POS-материалы/1.jpg";
import POS2 from "../images/services/POS-материалы/2.jpg";
import POS3 from "../images/services/POS-материалы/3.jpg";
import POS4 from "../images/services/POS-материалы/4.jpg";
import POS5 from "../images/services/POS-материалы/5.jpg";
//endregion

//region //Регистрация
import Brand1 from "../images/services/Регистрация товарных знаков/1.jpg";
import Brand2 from "../images/services/Регистрация товарных знаков/2.jpg";
import Brand3 from "../images/services/Регистрация товарных знаков/3.jpg";
import Brand4 from "../images/services/Регистрация товарных знаков/4.jpg";
import Brand5 from "../images/services/Регистрация товарных знаков/5.jpg";
import Brand6 from "../images/services/Регистрация товарных знаков/6.jpg";
//endregion

import { ServiceList } from "./types";

export const services: ServiceList = [
  {
    _id: '1',
    title: 'Дизайн и предпечатная подготовка',
    text: '- разработка дизайн-макетов, создание логотипа компании, брендбука, вёрстка полиграфической продукции.',
    imageUrl: [
      Design1,
      Design2,
      Design3,
      Design4,
      Design5,
      Design6,
      Design7,
      Design8,
      Design9,
      Design10,
      Design11,
      Design12,
    ]
  },
  {
    _id: '2',
    title: 'Полиграфия',
    text: '- печать визиток, листовок, открыток, изготовление книг, журналов, каталогов, буклетов, календарей, плакатов, упаковки и прочей печатной продукции.',
    imageUrl: [
      Polygraphy1,
      Polygraphy2,
      Polygraphy3,
      Polygraphy4,
      Polygraphy5,
      Polygraphy6,
      Polygraphy7,
      Polygraphy8,
      Polygraphy9,
      Polygraphy10,
    ]
  },
  {
    _id: '3',
    title: 'Сувенирная продукция',
    text: '- печать на ручки, флешки, брелки, значки, ежедневники, блокноты, зонты, часы, аксессуары для мобилных устройств, колонки, наушники, текстиль, посуду, спортивные товары и многое другое.',
    imageUrl: [
      Souvenirs1,
      Souvenirs2,
      Souvenirs3,
      Souvenirs4,
      Souvenirs5,
      Souvenirs6,
      Souvenirs7,
      Souvenirs8,
      Souvenirs9,
    ]
  },
  {
    _id: '4',
    title: 'Мерч и корпоративные подарки',
    text: '- нанесение логотипа, слогана компании на одежду и сувенирную продукцию, изготовление корпоративных подарков для сотрудников и партнеров компании.',
    imageUrl: [
      Gifts1,
      Gifts2,
      Gifts3,
      Gifts4,
      Gifts5,
      Gifts6,
      Gifts7,
      Gifts8,
      Gifts9,
    ]
  },
  {
    _id: '5',
    title: 'Широкоформатная и интерьерная печать',
    text: '- изговление баннеров, пресс-воллов, мобильных раздвижных рекламных стендов и других рекламных конструкций для оформления различных мероприятий.',
    imageUrl: [
      Print1,
      Print2,
      Print3,
      Print4,
    ]
  },
  {
    _id: '6',
    title: 'Наружная реклама',
    text: '- вывески, световые короба, объемные буквы, таблички, штендеры и т.д.',
    imageUrl: [
      Outdoor1,
      Outdoor2,
      Outdoor3,
      Outdoor4,
      Outdoor5,
      Outdoor6,
    ]
  },
  {
    _id: '7',
    title: 'Брендирование автомобилей',
    text: '- нанесение на кузове автомобиля логотипа и рекламных баннеров компании. Это делается с помощью специальной пленки..',
    imageUrl: [
      Auto1,
      Auto2,
      Auto3,
      Auto4,
      Auto5,
    ]
  },
  {
    _id: '8',
    title: 'Оперативная печать',
    text: '- срочное изготовление и печать различной полиграфической продукции.',
    imageUrl: [
      Urgent1,
      Urgent2,
      Urgent3,
      Urgent4,
    ]
  },
  {
    _id: '9',
    title: 'POS-материалы',
    text: '- изготовление разнообразных рекламных материалов, которые используются в точках продаж и в непосредственной близости к ним.',
    imageUrl: [
      POS1,
      POS2,
      POS3,
      POS4,
      POS5,
    ]
  },
  {
    _id: '10',
    title: 'Регистрация',
    text: '- товарного знака, логотипа, бренда.',
    imageUrl: [
      Brand1,
      Brand2,
      Brand3,
      Brand4,
      Brand5,
      Brand6,
    ]
  },
];
